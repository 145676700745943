import {
  analyticsChart,
  renderChartToScreen,
  getChartColor,
  getBorderColor,
  chartColors,
  borderColors,
} from "../util/charts";
import { API_URL, API_KEY } from "../util";

const TIMESPAN_LENGTHS = {
  quarter: 90,
  month: 30,
  week: 7,
};
let priceFilter;
let residenceFilter;
let entranceTimespanFilter = "week";
let exitTimespanFilter = "week";
const surveyResultMap = new Map([]);

document.addEventListener("turbolinks:load", () => {
  // if demographics page
  if (document.getElementById("demographics-page")) {
    analyticsChart("30-countries", false);
    analyticsChart("30-states", false);
    analyticsChart("30-cities", false);
    analyticsChart("ytd-countries", true);
    analyticsChart("ytd-states", true);
    analyticsChart("ytd-cities", true);

    setDemographicsButtonListeners();

    document
      .getElementById("entrance-timespan")
      .addEventListener("slider-change", (e) => {
        entranceTimespanFilter = e.detail.value;
        entranceSurveyResultsCharts(TIMESPAN_LENGTHS[entranceTimespanFilter]);
      });

    document
      .getElementById("exit-timespan")
      .addEventListener("slider-change", (e) => {
        exitTimespanFilter = e.detail.value;
        exitSurveyResultsCharts(TIMESPAN_LENGTHS[exitTimespanFilter]);
      });

    entranceSurveyResultsCharts();
    exitSurveyResultsCharts();
  }
});

const setPriceFilter = (newFilter) => {
  priceFilter = priceFilter === newFilter ? null : newFilter;
  exitSurveyResultsCharts(TIMESPAN_LENGTHS[exitTimespanFilter]);
};

const setResidenceFilter = (newFilter) => {
  residenceFilter = residenceFilter === newFilter ? null : newFilter;
  entranceSurveyResultsCharts(TIMESPAN_LENGTHS[entranceTimespanFilter]);
};

const getSurveyResults = async (days = TIMESPAN_LENGTHS["week"]) => {
  const surveyUrl = new URL(`${API_URL}/surveys/`);
  surveyUrl.searchParams.set("days", days + 1);
  let surveyData = surveyResultMap.get(surveyUrl.toJSON());
  if (!surveyData) {
    surveyData = await fetch(surveyUrl, {
      headers: { authorization: `Bearer ${API_KEY}` },
    }).then((res) => res.json());
    surveyResultMap.set(surveyUrl.toJSON(), surveyData);
  }

  const exitSurveyUrl = new URL(`${API_URL}/surveys/exit-surveys`);
  exitSurveyUrl.searchParams.set("days", days + 1);
  let exitSurveyData = surveyResultMap.get(exitSurveyUrl.toJSON());
  if (!exitSurveyData) {
    exitSurveyData = await fetch(exitSurveyUrl, {
      headers: { authorization: `Bearer ${API_KEY}` },
    }).then((res) => res.json());
    surveyResultMap.set(exitSurveyUrl.toJSON(), exitSurveyData);
  }

  return { surveyData, exitSurveyData };
};

const entranceSurveyResultsCharts = async (days) => {
  const { surveyData } = await getSurveyResults(days);
  customerResidenceChart(surveyData.data.surveys);
  familyShoppersChart(surveyData.data.surveys);
  firstTimerChart(surveyData.data.surveys);
  setSurveyCounts(surveyData.data.surveys.length);
};

const exitSurveyResultsCharts = async (days) => {
  const { exitSurveyData } = await getSurveyResults(days);
  pricePaidChart(exitSurveyData.data.surveys);
  priceExpectationChart(exitSurveyData.data.surveys);
  firstChoiceChart(exitSurveyData.data.surveys);
  showFamiliarityChart(exitSurveyData.data.surveys);
  setSurveyCounts(null, exitSurveyData.data.surveys.length);
};

const setSurveyCounts = (entranceCount, exitCount) => {
  if (entranceCount || entranceCount === 0)
    document.getElementById("survey-count-number").textContent = entranceCount;
  if (exitCount || exitCount === 0)
    document.getElementById("exit-survey-count-number").textContent = exitCount;
};

const pricePaidChart = (surveys) => {
  // Price paid
  let total = 0;
  const formatted = surveys.reduce((acc, survey) => {
    if (!survey.price) return acc;
    if (!acc[survey.price]) {
      acc[survey.price] = 0;
    }
    total++;
    acc[survey.price]++;
    return acc;
  }, {});

  const sortedLabels = Object.entries(formatted)
    .sort(
      ([a], [b]) => parseInt(a.replace("$", "")) - parseInt(b.replace("$", ""))
    )
    .map(([key]) => key);

  const data = {
    labels: sortedLabels,
    datasets: [
      {
        label: "Surveys",
        data: Object.entries(formatted)
          .sort(
            ([a], [b]) =>
              parseInt(a.replace("$", "")) - parseInt(b.replace("$", ""))
          )
          .map(([_, v]) => ((v / total) * 100).toFixed(2)),
        backgroundColor: getChartColor(1),
        borderColor: getBorderColor(1),
        borderWidth: 1,
      },
    ],
  };

  const config = {
    type: "bar",
    data,
    options: {
      indexAxis: "y",
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          beginAtZero: true,
          ticks: {
            // color: "#f0f0f0",
          },
        },
        x: {
          ticks: {
            // color: "#f0f0f0",
          },
        },
      },
      plugins: {
        legend: {
          position: "top",
          labels: {
            // color: "#f0f0f0",
          },
        },
        tooltip: {
          callbacks: {
            label: (context) => `${context.formattedValue}%`,
          },
        },
        title: {
          display: true,
          // color: "#f0f0f0",
          text: "Price paid (click a bar to drill down)",
        },
      },
      onClick: (event, bars, chart) => {
        const [bar] = bars;
        if (!bar) return;
        const { index } = bar;
        const key = sortedLabels[index];
        setPriceFilter(key);
      },
    },
  };
  renderChartToScreen("price-paid-chart", config);
};

const customerResidenceChart = (surveys) => {
  // Customer residence
  let total = 0;
  const formatted = surveys.reduce((acc, survey) => {
    if (!survey.residence) return acc;
    if (!acc[survey.residence]) {
      acc[survey.residence] = 0;
    }
    total++;
    acc[survey.residence]++;
    return acc;
  }, {});

  const data = {
    labels: Object.keys(formatted),
    datasets: [
      {
        label: "Surveys",
        data: Object.values(formatted).map((v) =>
          ((v / total) * 100).toFixed(2)
        ),
        backgroundColor: getChartColor(1),
        borderColor: getBorderColor(1),
        borderWidth: 1,
      },
    ],
  };

  const config = {
    type: "bar",
    data,
    options: {
      indexAxis: "y",
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          beginAtZero: true,
          ticks: {
            // color: "#f0f0f0",
          },
        },
        x: {
          ticks: {
            // color: "#f0f0f0",
          },
        },
      },
      plugins: {
        legend: {
          position: "top",
          labels: {
            // color: "#f0f0f0",
          },
        },
        tooltip: {
          callbacks: {
            label: (context) => `${context.formattedValue}%`,
          },
        },
        title: {
          display: true,
          // color: "#f0f0f0",
          text: "Customer residence (click a bar to drill down)",
        },
      },
      onClick: (event, bars, chart) => {
        const [bar] = bars;
        if (!bar) return;
        const { index } = bar;
        const key = Object.keys(formatted)[index];
        setResidenceFilter(key);
      },
    },
  };
  renderChartToScreen("customer-residence-chart", config);
};

const firstTimerChart = (surveys) => {
  // Filter by price
  if (residenceFilter) {
    surveys = surveys.filter((survey) => {
      return survey.residence === residenceFilter;
    });
  }

  // First timer
  let total = 0;
  const formatted = surveys.reduce((acc, survey) => {
    if (!survey.noob) return acc;
    if (!acc[survey.noob]) {
      acc[survey.noob] = 0;
    }
    acc[survey.noob]++;
    total++;
    return acc;
  }, {});

  const data = {
    labels: Object.keys(formatted),
    datasets: [
      {
        label: "Surveys",
        data: Object.values(formatted).map((v) =>
          ((v / total) * 100).toFixed(2)
        ),
        backgroundColor: chartColors,
        borderColor: borderColors,
        borderWidth: 1,
      },
    ],
  };

  let title = ["First time Bway ticket buyers"];
  if (residenceFilter) {
    title.push(`for those residing in ${residenceFilter}`);
  }

  const config = {
    type: "pie",
    data,
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: "top",
          labels: {
            // color: "#f0f0f0",
          },
          // color: "#f0f0f0",
        },
        tooltip: {
          callbacks: {
            label: (context) => `${context.formattedValue}%`,
          },
        },
        title: {
          display: true,
          // color: "#f0f0f0",
          text: title,
        },
      },
    },
  };
  renderChartToScreen("first-timer-chart", config);
};

const familyShoppersChart = (surveys) => {
  // Filter by price
  if (residenceFilter) {
    surveys = surveys.filter((survey) => {
      return survey.residence === residenceFilter;
    });
  }

  // Family shoppers
  let total = 0;
  const formatted = surveys.reduce((acc, survey) => {
    if (!survey.family) return acc;
    if (!acc[survey.family]) {
      acc[survey.family] = 0;
    }
    total++;
    acc[survey.family]++;
    return acc;
  }, {});

  const data = {
    labels: Object.keys(formatted),
    datasets: [
      {
        label: "Surveys",
        data: Object.values(formatted).map((v) =>
          ((v / total) * 100).toFixed(2)
        ),
        backgroundColor: chartColors,
        borderColor: borderColors,
        borderWidth: 1,
      },
    ],
  };

  const title = ["Family shoppers"];
  if (residenceFilter) {
    title.push(`for those residing in ${residenceFilter}`);
  }

  const config = {
    type: "pie",
    data,
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: "top",
          labels: {
            // color: "#f0f0f0",
          },
        },
        tooltip: {
          callbacks: {
            label: (context) => `${context.formattedValue}%`,
          },
        },
        title: {
          display: true,
          // color: "#f0f0f0",
          text: title,
        },
      },
    },
  };
  renderChartToScreen("family-shoppers-chart", config);
};

const showFamiliarityChart = (surveys) => {
  // Filter by price
  if (priceFilter) {
    surveys = surveys.filter((survey) => {
      return survey.price === priceFilter;
    });
  }

  // Show familiarity
  let total = 0;
  const formatted = surveys.reduce((acc, survey) => {
    if (!survey.knowChoice) return acc;
    if (!acc[survey.knowChoice]) {
      acc[survey.knowChoice] = 0;
    }
    total++;
    acc[survey.knowChoice]++;
    return acc;
  }, {});

  const data = {
    labels: Object.keys(formatted),
    datasets: [
      {
        label: "Surveys",
        data: Object.values(formatted).map((v) =>
          ((v / total) * 100).toFixed(2)
        ),
        backgroundColor: chartColors,
        borderColor: borderColors,
        borderWidth: 1,
      },
    ],
  };

  const title = ["Familiarity with purchased show prior to visiting TKTS"];
  if (priceFilter) {
    title.push(`for those who paid ${priceFilter}`);
  }

  const config = {
    type: "pie",
    data,
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: "top",
          labels: {
            // color: "#f0f0f0",
          },
        },
        tooltip: {
          callbacks: {
            label: (context) => `${context.formattedValue}%`,
          },
        },
        title: {
          display: true,
          // color: "#f0f0f0",
          text: title,
        },
      },
    },
  };
  renderChartToScreen("show-familiarity-chart", config);
};

const firstChoiceChart = (surveys) => {
  // Filter by price
  if (priceFilter) {
    surveys = surveys.filter((survey) => {
      return survey.price === priceFilter;
    });
  }

  // First choice
  let total = 0;
  const formatted = surveys.reduce((acc, survey) => {
    if (!survey.choice) return acc;
    if (!acc[survey.choice]) {
      acc[survey.choice] = 0;
    }
    total++;
    acc[survey.choice]++;
    return acc;
  }, {});

  const data = {
    labels: Object.keys(formatted),
    datasets: [
      {
        label: "Surveys",
        data: Object.values(formatted).map((v) =>
          ((v / total) * 100).toFixed(2)
        ),
        backgroundColor: chartColors,
        borderColor: borderColors,
        borderWidth: 1,
      },
    ],
  };

  const title = ["First choice show purchased at the window"];
  if (priceFilter) {
    title.push(`for those who paid ${priceFilter}`);
  }

  const config = {
    type: "pie",
    data,
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: "top",
          labels: {
            // color: "#f0f0f0",
          },
        },
        tooltip: {
          callbacks: {
            label: (context) => `${context.formattedValue}%`,
          },
        },
        title: {
          display: true,
          // color: "#f0f0f0",
          text: title,
        },
      },
    },
  };
  renderChartToScreen("first-choice-chart", config);
};

const priceExpectationChart = (surveys) => {
  // Filter by price
  if (priceFilter) {
    surveys = surveys.filter((survey) => {
      return survey.price === priceFilter;
    });
  }

  // Price expectation
  let total = 0;
  const formatted = surveys.reduce((acc, survey) => {
    if (!survey.expect) return acc;
    if (!acc[survey.expect]) {
      acc[survey.expect] = 0;
    }
    total++;
    acc[survey.expect]++;
    return acc;
  }, {});

  const data = {
    labels: Object.keys(formatted),
    datasets: [
      {
        label: "Surveys",
        data: Object.values(formatted).map((v) =>
          ((v / total) * 100).toFixed(2)
        ),
        backgroundColor: chartColors,
        borderColor: borderColors,
        borderWidth: 1,
      },
    ],
  };

  const title = ["Price expectation"];
  if (priceFilter) {
    title.push(`for those who paid ${priceFilter}`);
  }

  const config = {
    type: "pie",
    data,
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: "top",
          labels: {
            // color: "#f0f0f0",
          },
        },
        tooltip: {
          callbacks: {
            label: (context) => `${context.formattedValue}%`,
          },
        },
        title: {
          display: true,
          // color: "#f0f0f0",
          text: title,
        },
      },
    },
  };
  renderChartToScreen("price-expectation-chart", config);
};

const setDemographicsButtonListeners = () => {
  const cards = document.querySelectorAll(".charts .card__body");
  document.querySelectorAll("button.more")?.forEach((button, i) => {
    button.addEventListener("click", () => {
      const card = cards[i];
      card.classList.add("open");
    });
  });
  document.querySelectorAll("button.less")?.forEach((button, i) => {
    button.addEventListener("click", () => {
      const card = cards[i];
      card.classList.remove("open");
    });
  });
};
