document.addEventListener("turbolinks:load", function() {

  htmx.process(document.body);

  if (document.querySelector("textarea:not(.plain)")) {
    tinymce.remove(); // required because of turbolinks
    tinymce.init({
      selector: "textarea:not(.plain)",
      plugins:
        "advlist autolink lists link image charmap print preview hr anchor pagebreak",
      toolbar:
        "undo redo | styleselect | numlist bullist | fontsizeselect | bold italic | alignleft aligncenter alignright alignjustify | outdent indent",
      toolbar_mode: "floating",
      content_style: "body { font-size: 12px; }",
    });
  }

  function handleSearchParams() {
    const params = new URLSearchParams(window.location.search);
    if (params.get("qr")?.toLowerCase() === "true") {
      setTimeout(() => {
        history.replaceState({}, "", window.location.pathname);
      }, 100);
    }
  }
  handleSearchParams();
});
